$h1color: #17172d;
$h3color: #888;
$widthoftable: 31%;
$bgctables: #ffffff;
$bgfontcolor: #717787;
$green: #72b545;
$turquoise: #108a86;

.pricingTable{
  margin: 40px auto;

  &>&-title{
    text-align: center;
    color: $h1color;
    font-size: 3em !important; 
    font-size: 300%;
    margin-bottom: 10px;
    letter-spacing: 0.04em;
  }
  
  &>&-subtitle{
    text-align: center;
    color: $h3color;
    font-size: 1.8em;
    letter-spacing: 0.04em;
    margin-top: 0;
    margin-bottom: 60px;
    
    @media screen and (max-width: 480px){
      margin-bottom: 30px;
    }
  }
  
  &-firstTable{
    list-style: none;
    text-align: center;
    padding-inline-start: 0;
    
    &_table{
      vertical-align: middle;
      width: $widthoftable;
      background-color: $bgctables;
      display: inline-block;
      padding: 0px 30px 40px;
      text-align: center;
      transition: all 0.3s ease;
      border-radius: 40px;
      border-top: 8px solid $turquoise;
      border-bottom: 8px solid $turquoise;
      
      @media screen and (max-width: 992px){
        display: block;
        width: 100%;
        margin: 0 auto;
        max-width: 100%;
        margin-bottom: 20px;
        padding: 10px;
        padding-left: 20px;
      }
      
      & > * {
         @media screen and (max-width: 992px){
           display: inline-block;
           vertical-align: middle;
        }
        
         @media screen and (max-width: 480px){
          display: block;
           float: none;
        }
      }
      
      &:after {
         @media screen and (max-width: 992px){
           display: table;
           content: '';
           clear: both;
        }
      }
      
      &:hover{
        transform: scale(1.08);
        @media screen and (max-width: 992px){
          transform: none;
        }
      }
      
      &:not(:last-of-type){
        margin-right: (100%-$widthoftable*3)/2;
         @media screen and (max-width: 992px){
           margin-right: auto;
         }
      }
      
      &:nth-of-type(2){
        position: relative;
        border-top: 8px solid $green;
        border-bottom: 8px solid $green; 
        
        & li:before{
          background-color: $green;
        }
        
        & h1{
          color: $green;
        }
        
        &:before{
          content: 'Mais benef&iacute;cios';
          position: absolute;
          color: white;
          display: block;
          background-color: $green;
          text-align: center;
          right: 15px;
          top: -25px;
          height: 65px;
          width: 65px;
          border-radius: 50%;
          box-sizing: border-box;
          font-size: 0.55em;
          padding-top: 22px;
          text-transform: uppercase;
          letter-spacing: 0.13em;
          transition: all 0.5s ease;
          font-weight: bold;
          
          @media screen and (max-width: 992px){
            left: 10px;
            width: 55px;
            height: 55px;
            font-size: 0.48em;
            top: -10px;
            padding-top: 15px;
          }
        }
        &:hover:before{
           transform: rotate(360deg);
        }
      }
      
      &__header{
        color: $h1color;
        font-size: 1.6em;
        padding: 30px 0px;
        border-bottom: 2px solid #ebedec;
        letter-spacing: 0.03em;

        @media screen and (max-width: 1200px){
          font-size: 1.4em;
          padding: 30px 0px 20px;
       }
        
        @media screen and (max-width: 1068px){
           font-size: 1.45em;
        }
         @media screen and (max-width: 992px){
            padding: 0px;
            border-bottom: none;
            float: left;
            width: 33%;
           padding-top: 5%;
           padding-bottom: 2%;
        }
        
        @media screen and (max-width: 768px){
          font-size: 1.3em !important;
          float: none;
          width: 100%;
        }
        
        @media screen and (max-width: 480px){
          float: none;
          width: 100%;
          font-size: 1.8em;
          padding: 20px 0px 20px;
        }
        
      }
      
      &__pricing{
        color: #17172d;
        font-size: 4em !important;
        padding: 30px 0px;
        border-bottom: 2px solid #ebedec;
        line-height: 0.7 !important;
        letter-spacing: -0.02em;

        @media screen and (max-width: 1200px){
          font-size: 3.5em !important;
       }
        
         @media screen and (max-width: 1068px){
           font-size: 3.2em !important;
        }
        
        @media screen and (max-width: 992px){
          border-bottom: none;
          padding: 20px 0 0 0;
          float: left;
          clear: left;
          width: 33%; 
        }
        
         @media screen and (max-width: 768px){
          padding: 10px 0 0 0;
          float: none;
          width: 100%;
        }
        
         @media screen and (max-width: 480px){
           float: none;
           width: 100%;
           font-size: 3em !important;
           margin-bottom: 10px;
        }
        
        span:first-of-type{
          font-size: 0.4em;
          vertical-align: top;
          letter-spacing: 0.04em;
          padding-right: 2px;
           @media screen and (max-width: 1068px){
           font-size: 0.3em;
          }
        }
        span:nth-of-type(2){
          font-weight: bold;
        }
        span:last-of-type{
          vertical-align: bottom;
          font-size: 0.4em;
          letter-spacing: 0.04em;
          padding-left: 0.2em;
           @media screen and (max-width: 1068px){
           font-size: 0.25em;
          }
        }
      }

      &__label {

        @media screen and (max-width: 992px){
          width: 33%;
          float: left;
          clear: left;
          margin-top: 20px;
        }

        @media screen and (max-width: 768px) {
          float: none;
          width: 100%;
        }
      }

      &__select {
        display: block;
        width: 100%;
        margin: 10px 0 15px;
        font-size: 15px;
        line-height: 1.2; 
        padding: 5px;
        border: 1px solid #b4bdc6;
        border-radius: 4px;
        color: #6e768d;

        @media screen and (max-width: 992px){
          width: 33%;
          float: left;
          clear: left;
        }
        
        @media screen and (max-width: 768px) {
          float: none;
          width: 100%;
          max-width: 300px;
          margin-right: auto;
          margin-left: auto;
          
        }
        
      }
      
      &__options{
        list-style: none;
        font-size: 0.9em;
        border-bottom: 2px solid #ebedec;
        padding-inline-start: 0;
        padding-bottom: 10px;
        
         @media screen and (max-width: 1068px){
           font-size: 0.85em;
        }
        
        @media screen and (max-width: 992px){
          border-bottom: none;
          padding: 0;
          margin-right: 10%;
        }
        
         @media screen and (max-width: 610px){
          font-size: 0.7em;
        }
        
        @media screen and (max-width: 480px){
          padding: 15px;
          padding-left: 40px;
          font-size: 1.3em;
          margin-right: 0;
          margin-bottom: 10px;
          width: 80%;
        }
        
        
        &>li{
          padding: 8px 0px 8px 35px;  
          text-align: left;         
          position: relative;
          
           @media screen and (max-width: 992px){
               text-align: left;    
            }
          
           @media screen and (max-width: 610px){
            padding: 5px 0;
          }
          
           @media screen and (max-width: 480px){
            //  text-align: center;
          }

          &:before{
            content: '';
            margin-right: 15px;
            color: white;
            background-color: $turquoise;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            font-size: 0.8em;
            padding: 2px;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;

            
           @media screen and (max-width: 610px){
            left: -35px;
          }
          }
        }
      }

      &__getstart{
        color: white;
        background-color: $green;
        margin-top: 30px;
        margin-bottom: 0;
        border-radius: 40px;
        cursor: pointer;
        padding: 15px;
        box-shadow: 0px 3px 0px 0px rgba(102, 172, 100, 1);
        letter-spacing: 0.07em;
        transition: all 0.4s ease;
        width: 100%;
        font-size: 1.1em;
        display: block;
        
         @media screen and (max-width: 1068px){
          font-size: 0.95em;
        }
        
        @media screen and (max-width: 992px){
          width: 60%;
          margin-top: 20px;
          margin-bottom: 15px;
          margin-left: auto;
        }
        
         @media screen and (max-width: 768px){
          margin-right: auto;
        }
        
        @media screen and (max-width: 480px){
          font-size: 1em;
          width: 50%;
          margin: 10px auto;
        }
        
        &:hover{
          transform: translateY(-10px);
          box-shadow: 0px 20px 15px -9px rgba(102, 172, 100, 0.9);
          @media screen and (max-width: 992px){
            transform: none;
            box-shadow: none;
          }
        }
        
        &:active{
          box-shadow: inset 0 0 10px 1px rgba(102, 165, 100, 1), 0px 20px 15px -9px rgba(102, 172, 100, 0.95);
          transform: scale(0.95) translateY(-9px);
          @media screen and (max-width: 992px){
            transform: scale(0.95) translateY(0);
            box-shadow:  none;
          }
        }
      }
    }
    
   
  }
}
